import axios from "axios";
import { API_URL } from "../constants";

export const getDocumentByConsultant = async (consultantId) => {
  try {
    const response = await axios.get(`${API_URL}/document?consultantId=${consultantId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const downloadDoc = async (docId) => {
  try {
    const response = await axios.get(`${API_URL}/document/download/${docId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      responseType: "blob"
    });
    return response;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const createDoc = async (form) => {
  try {
    const response = await axios.post(`${API_URL}/document`, form, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const deleteDoc = async (docId) => {
  try {
    const response = await axios.delete(`${API_URL}/document/${docId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};
