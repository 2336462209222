import axios from "axios";
import { API_URL } from "../constants";

export const performDeepSearch = async (searchTerm) => {
  try {
    const response = await axios.get(`${API_URL}/consultant?searchTerm=${searchTerm}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const getConsultantByUserId = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/consultant?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const createConsultant = async (consultant) => {
  try {
    const response = await axios.post(`${API_URL}/consultant`, consultant, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const getAllConsultant = async () => {
  try {
    const response = await axios.get(`${API_URL}/consultant`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const getConsultantById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/consultant/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};
