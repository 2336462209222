import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {}
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    }
  }
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
