import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Typography, Box } from "@mui/material";

const Loading = ({ show, text }) => {
  if (!show) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bgcolor="rgba(255, 255, 255, 0.5)"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor="white"
        p={3}
        borderRadius={3}
        boxShadow={3}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: "1rem" }}>
          {text || "Cargando"}...
        </Typography>
      </Box>
    </Box>
  );
};

Loading.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string
};

export default Loading;
