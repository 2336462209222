import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  consultantId: ""
};

const consultantSlice = createSlice({
  name: "consultantSlice",
  initialState,
  reducers: {
    updateConsultantId: (state, action) => {
      state.consultantId = action.payload;
    }
  }
});

export const { updateConsultantId } = consultantSlice.actions;

export default consultantSlice.reducer;
