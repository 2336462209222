import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton
} from "@mui/material";
import DialogWarning from "./DialogWarning";
import { Controller, useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateUser as updateReduxUser } from "../redux/userSlice";
import Loading from "./Loading";
import { deleteUser, updateUser } from "../services/userServices";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/authProvider";

const UserProfile = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setToken } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassRequired, setNewPassRequired] = useState(false);
  const userData = useSelector((state) => state.user.user);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleDeleteAccount = async () => {
    setShowLoading(true);
    setError("");
    const response = await deleteUser(userData.id);
    setShowLoading(false);
    if (response.message) {
      setError(response.message);
    } else {
      dispatch(updateReduxUser({}));
      setDialogOpen(false);
      setToken();
      navigate("/");
    }
  };

  const onSubmit = async (data) => {
    setShowLoading(true);
    setError("");
    const response = await updateUser(userData.id, data.email, data.password, data.passwordNew);
    setShowLoading(false);
    if (response.message) {
      setError(response.message);
    }
  };

  const passwordValue = watch("password");
  useEffect(() => {
    setNewPassRequired(!!passwordValue);
  }, [passwordValue]);

  return (
    <Box sx={{ backgroundColor: "white", padding: "2rem", borderRadius: "4px", boxShadow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Actualizar correo electrónico y/o contraseña
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} onSubmit={handleSubmit(onSubmit)} component="form">
            <Grid item xs={12} sm={12}>
              <Controller
                name="email"
                control={control}
                defaultValue={userData.email}
                rules={{
                  required: { value: true, message: "Correo electrónico es requerido" },
                  pattern: { value: /^\S+@\S+$/i, message: "Correo electrónico no válido" }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Correo Electrónico"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    inputProps={{ maxLength: 50 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  pattern: {
                    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/,
                    message:
                      "Contraseña debe contener al menos una minúscula, una mayúscula y un número"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Contraseña Actual"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{ maxLength: 45 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Ver contraseña"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="passwordNew"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: newPassRequired,
                    message:
                      "Contraseña nueva es requerida. Ingresar la contraseña no es obligatorio si solo deseas cambiar tu correo electrónico"
                  },
                  pattern: {
                    value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/,
                    message:
                      "Contraseña debe contener al menos una minúscula, una mayúscula y un número"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Contraseña Nueva"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    inputProps={{ maxLength: 45 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Ver contraseña"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!errors.passwordNew}
                    helperText={errors.passwordNew ? errors.passwordNew.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Actualizar
              </Button>
              <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
                {error}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Eliminar tu cuenta
          </Typography>
          <Typography variant="body1" color="error" gutterBottom>
            Advertencia: Esta acción no puede revertirse. Todos tus datos serán eliminados de
            nuestras bases de datos.
          </Typography>
          <Button variant="contained" color="secondary" onClick={() => setDialogOpen(true)}>
            Eliminar Cuenta
          </Button>
        </Grid>
      </Grid>
      <DialogWarning
        open={dialogOpen}
        dialogText="¿Estás seguro de que deseas eliminar tu cuenta?"
        onCloseFn={() => setDialogOpen(false)}
        onConfirmFn={handleDeleteAccount}
      />
      <Loading show={showLoading} text={"Guardando"} />
    </Box>
  );
};

export default UserProfile;
