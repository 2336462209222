import React from "react";
import { Typography, Container, Box } from "@mui/material";
import SideMenu from "../components/SideMenu";
import { useTheme } from "@mui/material/styles";
import HelpContent from "../components/HelpContent";
import { useSelector } from "react-redux";

const OperatorDashboard = () => {
  const theme = useTheme();
  const userData = useSelector((state) => state.user.user);

  return (
    <Box sx={{ backgroundColor: theme.palette.pageBackground, minHeight: "100vh" }}>
      <div style={{ display: "flex" }}>
        <SideMenu />
        <main style={{ flexGrow: 1, padding: "24px" }}>
          <div style={{ minHeight: "64px" }} />
          <Container>
            <Typography variant="h4" gutterBottom>
              {`${userData.name} ${userData.lastname}, este es tu centro de ayuda`}
            </Typography>
            <HelpContent />
          </Container>
        </main>
      </div>
    </Box>
  );
};

export default OperatorDashboard;
