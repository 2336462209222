import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tab,
  Tabs
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import ConsultantForm from "../components/ConsultantForm";
import CreditsForm from "../components/CreditsForm";
import DocumentsForm from "../components/DocumentsForm";
import SideMenu from "../components/SideMenu";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { getConsultantById } from "../services/consultantServices";
import { getCreditByConsultant } from "../services/creditServices";
import { getReasonsByConsultant } from "../services/reasonsServices";
import { getDocumentByConsultant } from "../services/docsServices";
import CustomTabPanel from "../components/CustomTabPanel";
import DebtReasons from "../components/DebtReasons";
import { ROLE } from "../constants";
import { createSession, deleteSession, getSessionsByConsultant } from "../services/sessionServices";
import Loading from "../components/Loading";

const Consultant = () => {
  // configuration
  const theme = useTheme();
  const consultantId = useSelector((state) => state.consultant.consultantId);
  const userData = useSelector((state) => state.user.user);
  const [showLoading, setShowLoading] = useState(false);
  const [loadText, setLoadText] = useState("Guardando");
  const [isAdmin, setIsAdmin] = useState(false);

  // consultant itself
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState("");
  const [date, setDate] = useState(new Date());

  // note popup handle
  const [openNotePopup, setOpenNotePopup] = useState(false);
  const [popupValue, setPopupValue] = useState("");
  const [popupDate, setPopupDate] = useState(new Date());
  const [popupType, setPopupType] = useState("");

  // all consultant data
  const [currentTab, setCurrentTab] = useState(0);
  const [consultant, setConsultant] = useState({});
  const [credits, setCredits] = useState([]);
  const [reasons, setReasons] = useState(null);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    setIsAdmin(
      userData.Roles.some((role) => role.name === ROLE.CONSULTANT || role.name === ROLE.ADMIN)
    );
  }, [userData]);
  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      const response = await getConsultantById(consultantId);
      if (!ignore) {
        setConsultant(response);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [consultantId]);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      if (consultant && consultant.id) {
        const credits = await getCreditByConsultant(consultant.id);
        const reasons = await getReasonsByConsultant(consultant.id);
        const docs = await getDocumentByConsultant(consultant.id);
        const rNotes = await getSessionsByConsultant(consultant.id);
        if (!ignore) {
          setCredits(credits);
          setReasons(reasons);
          setDocs(docs);
          const filteredNotes = isAdmin ? rNotes : rNotes.filter((note) => !note.private);
          setNotes(filteredNotes);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [consultant]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleSaveNote = async () => {
    setLoadText("Guardando");
    setShowLoading(true);
    const payload = {
      consultantId,
      attendedBy: userData.id,
      note,
      date,
      privateNote: isAdmin,
      sessionDate: date
    };
    const response = await createSession(payload);
    setNotes([...notes, response]);
    setNote("");
    setDate(new Date());
    setShowLoading(false);
  };

  const handleOpenNote = (note) => {
    setOpenNotePopup(true);
    setPopupValue(note.note);
    setPopupDate(note.createdAt);
    setPopupType(note.private ? "Privada (Creada por Asesor)" : "General (Creada por Operador)");
  };

  const deleteNote = async (noteId) => {
    setLoadText("Eliminando");
    setShowLoading(true);
    await deleteSession(noteId);
    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
    setShowLoading(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.pageBackground,
        minHeight: "100vh",
        padding: "2rem",
        borderRadius: "4px",
        boxShadow: 1
      }}
    >
      <div style={{ display: "flex" }}>
        <SideMenu />
        <main style={{ flexGrow: 1, padding: "2px" }}>
          <div style={{ minHeight: "64px" }} />
          <Box
            sx={{
              backgroundColor: "white",
              padding: 2,
              borderRadius: "4px",
              boxShadow: 1,
              minWidth: "90%"
            }}
          >
            <Typography variant="h4" gutterBottom>
              {`Asesoría: ${consultant?.User?.name || ""} ${consultant?.User?.lastname || ""}`}
            </Typography>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={8}>
                <Tabs
                  value={currentTab}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Generales" />
                  <Tab label="Créditos" />
                  <Tab label="Documentos" />
                  <Tab label="Razones de Endeudamiento" />
                </Tabs>
                <CustomTabPanel index={currentTab} value={0}>
                  <ConsultantForm consultant={consultant} onModelChange={setConsultant} />
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={1}>
                  <CreditsForm
                    creditList={credits}
                    consultantId={consultant.id}
                    onModelChange={setCredits}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={2}>
                  <DocumentsForm docs={docs} consultantId={consultant.id} onModelChange={setDocs} />
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={3}>
                  <DebtReasons
                    reasons={reasons}
                    consultantId={consultant.id}
                    onModelChange={setReasons}
                  />
                </CustomTabPanel>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    boxShadow: 1,
                    padding: "24px"
                  }}
                >
                  <Typography variant="h5">Notas de Asesoría</Typography>
                  <Box sx={{ marginBottom: "16px" }}>
                    <TextField
                      label="Nota"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      sx={{ marginBottom: "16px" }}
                    />
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Fecha"
                          value={date}
                          onChange={(newDate) => setDate(newDate)}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          sx={{ marginRight: "16px" }}
                        />
                      </LocalizationProvider>
                      <Button variant="contained" color="primary" onClick={handleSaveNote}>
                        Guardar Nota
                      </Button>
                    </Box>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Nota</TableCell>
                            <TableCell sx={{ width: "96px" }}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notes.map((note, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {new Date(note.createdAt).toLocaleDateString("es-GT")}
                              </TableCell>
                              <TableCell>
                                {note.note.substring(0, Math.min(note.note.length, 25))}
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Ver nota">
                                  <IconButton onClick={() => handleOpenNote(note)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Elimina la nota">
                                  <IconButton onClick={() => deleteNote(note.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Dialog open={openNotePopup} onClose={() => setOpenNotePopup(false)}>
              <DialogTitle>
                <Typography variant="h6">
                  Fecha: {new Date(popupDate).toLocaleDateString("es-GT")}
                </Typography>
                <Typography variant="h6">Tipo: {popupType}</Typography>
              </DialogTitle>
              <DialogContent>
                <div>
                  {popupValue.split("\n").map((i, key) => {
                    return (
                      <Typography variant="body2" key={key}>
                        {i}
                      </Typography>
                    );
                  })}
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenNotePopup(false)} color="primary">
                  Cerrar
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </main>
      </div>
      <Loading show={showLoading} text={loadText} />
    </Box>
  );
};

export default Consultant;
