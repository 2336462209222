import React, { useEffect, useState } from "react";
import { Typography, Container, Box } from "@mui/material";
import SideMenu from "../components/SideMenu";
import { useTheme } from "@mui/material/styles";
import OperatorTable from "../components/OperatorTable";
import { useSelector } from "react-redux";
import { getAllConsultant } from "../services/consultantServices";

const OperatorDashboard = () => {
  const theme = useTheme();
  const userData = useSelector((state) => state.user.user);
  const [consultants, setConsultants] = useState([]);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      const response = await getAllConsultant();
      if (!ignore) {
        setConsultants(response);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <Box sx={{ backgroundColor: theme.palette.pageBackground, minHeight: "100vh" }}>
      <div style={{ display: "flex" }}>
        <SideMenu />
        <main style={{ flexGrow: 1, padding: "24px" }}>
          <div style={{ minHeight: "64px" }} />
          <Container>
            <Typography variant="h4" gutterBottom>
              {`¡Bienvenido, ${userData.name} ${userData.lastname}!`}
            </Typography>
            <OperatorTable consultants={consultants} />
          </Container>
        </main>
      </div>
    </Box>
  );
};

export default OperatorDashboard;
