import axios from "axios";
import { API_URL } from "../constants";

export const getReasonsByConsultant = async (consultantId) => {
  try {
    const response = await axios.get(`${API_URL}/reason?consultantId=${consultantId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const createReasons = async (reasons) => {
  try {
    const response = await axios.post(`${API_URL}/reason`, reasons, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};
