import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Link,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DialogPrivacy from "../components/DialogPrivacy";
import DialogAboutUs from "../components/DialogAboutUs";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../auth/authProvider";
import { userLogin } from "../services/userServices";
import { ROLE } from "../constants";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/userSlice";
import Loading from "../components/Loading";
import Logo from "../logo192.png";

const Login = () => {
  const dispatch = useDispatch();
  const { setToken } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 500) {
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setShowLoading(true);
    setError("");
    const loginResponse = await userLogin(data.email, data.password);
    function rolesHandler() {
      if (loginResponse.Roles.some((role) => role.name === ROLE.USER)) {
        navigate("/proceso");
      } else if (
        loginResponse.Roles.some((role) => role.name === ROLE.OPERATOR) ||
        loginResponse.Roles.some((role) => role.name === ROLE.CONSULTANT)
      ) {
        navigate("/asesorias");
      } else {
        navigate("/cuenta");
      }
    }
    if (loginResponse) {
      if (loginResponse.token) {
        setToken(loginResponse.token);
        dispatch(updateUser(loginResponse));
        setTimeout(rolesHandler, 500);
      }
      if (loginResponse.message) {
        setError(loginResponse.message);
      }
    }
    setShowLoading(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.pageBackgroundSecondary,
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={2}>
        <img src={Logo} width="70%" alt={"FFCI"} />
      </Box>
      <Container maxWidth="sm" sx={{ backgroundColor: "white", padding: 4, borderRadius: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Iniciar Sesión
          </Typography>
          <Typography>
            Bienvenido a nuestro sistema de gestión de tu proceso de asesoría con FFCI. Por favor,
            inicia sesión con tu correo electrónico y contraseña.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Correo Electrónico"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Ver contraseña"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
            <Link variant="body2" sx={{ marginTop: 2 }} onClick={() => navigate("/nuevo-password")}>
              ¿Olvidaste tu contraseña?
            </Link>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Iniciar Sesión
            </Button>
            <Typography variant="body1" color="error" sx={{ marginTop: 2 }}>
              {error}
            </Typography>
          </form>
          <Link
            variant="body2"
            sx={{ marginTop: 2 }}
            onClick={() => navigate("/registro")}
            component="button"
          >
            ¿Todavía no tienes una cuenta? Registrate
          </Link>
        </Box>
      </Container>
      <Box
        position="fixed"
        bottom={isKeyboardOpen ? -100 : 16}
        left={16}
        display="flex"
        flexDirection="column"
      >
        <Link
          href="#"
          variant="body2"
          onClick={() => setOpenPopup1(true)}
          sx={{ marginBottom: 1, display: isKeyboardOpen ? "none" : "block" }}
          className="App-link"
        >
          Acuerdo de Privacidad
        </Link>
        <Link
          href="#"
          variant="body2"
          onClick={() => setOpenPopup2(true)}
          className="App-link"
          sx={{ display: isKeyboardOpen ? "none" : "block" }}
        >
          Acerca de Nosotros
        </Link>
      </Box>
      <DialogPrivacy openPopup={openPopup1} onCloseFn={() => setOpenPopup1(false)} />
      <DialogAboutUs openPopup={openPopup2} onCloseFn={() => setOpenPopup2(false)} />
      <Loading show={showLoading} />
    </Box>
  );
};

export default Login;
