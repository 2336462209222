import axios from "axios";
import { API_URL } from "../constants";

export const getSessionsByConsultant = async (consultantId) => {
  try {
    const response = await axios.get(`${API_URL}/session?consultantId=${consultantId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const createSession = async (note) => {
  try {
    const response = await axios.post(`${API_URL}/session`, note, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const deleteSession = async (sessionId) => {
  try {
    const response = await axios.delete(`${API_URL}/session/${sessionId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};
