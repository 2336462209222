import React, { useEffect, useState } from "react";
import { Grid, Checkbox, FormControlLabel, Button, Box } from "@mui/material";
import { debtReasons } from "../constants";
import { createReasons } from "../services/reasonsServices";
import Loading from "./Loading";
import PropTypes from "prop-types";

const DebtReasons = ({ reasons, consultantId, onModelChange }) => {
  const [checkboxes, setCheckboxes] = useState(debtReasons);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (reasons) {
      initializeCheckboxes(reasons);
    }
  }, [reasons]);

  const initializeCheckboxes = (reasons) => {
    const updatedCheckboxes = debtReasons.map((item) => ({
      ...item,
      value: reasons[item.id] !== null ? reasons[item.id] : item.value
    }));
    setCheckboxes(updatedCheckboxes);
  };

  const handleCheckboxChange = (id) => {
    setCheckboxes((prev) =>
      prev.map((item) => (item.id === id ? { ...item, value: !item.value } : item))
    );
  };

  const handleSave = async () => {
    setShowLoading(true);
    const payload = {};
    checkboxes.forEach((item) => {
      payload[item.id] = item.value;
    });
    payload.consultantId = consultantId;
    const reasonsResponse = await createReasons({ ...payload });
    onModelChange(reasonsResponse);
    setShowLoading(false);
  };

  return (
    <Box sx={{ maxWidth: "85%", margin: "auto", padding: 2 }}>
      <Grid container spacing={2}>
        {checkboxes.map((item) => (
          <Grid item xs={12} sm={6} key={item.id}>
            <FormControlLabel
              control={
                <Checkbox checked={item.value} onChange={() => handleCheckboxChange(item.id)} />
              }
              label={item.label}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
            Guardar Información
          </Button>
        </Grid>
      </Grid>
      <Loading show={showLoading} text={"Guardando"} />
    </Box>
  );
};

export default DebtReasons;

DebtReasons.propTypes = {
  reasons: PropTypes.object,
  consultantId: PropTypes.number,
  onModelChange: PropTypes.func.isRequired
};
