import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/material/locale";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#14344e"
        // main: "#202123"
      },
      secondary: {
        main: "#dc004e"
      },
      action: {
        hover: "#29679b"
        // hover: "#2D2D2E"
      },
      pageBackground: "#f6f7f8",
      pageBackgroundSecondary: "#14344e"
    },
    typography: {
      h1: {
        fontWeight: 800
      },
      h2: {
        fontWeight: 700
      },
      h3: {
        fontWeight: 600
      },
      h4: {
        fontWeight: 500
      },
      h5: {
        fontWeight: 400
      },
      h6: {
        fontWeight: 300
      }
    }
  },
  esES
);

export default theme;
