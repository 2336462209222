import React from "react";
import { useAuth } from "./authProvider";
import { Navigate, useLocation } from "react-router-dom";

export const PrivateElement = ({ children }) => {
  let { token } = useAuth();
  let location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  // let user through if they're logged in
  return children;
};
