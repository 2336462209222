import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Container, Typography, Box, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DialogPrivacy from "../components/DialogPrivacy";
import DialogAboutUs from "../components/DialogAboutUs";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Logo from "../logo192.png";
import { resetPassword } from "../services/userServices";
import DialogConfirmation from "../components/DialogConfirmation";

const NewPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openRegConf, setOpenRegConf] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 500) {
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onSubmit = async (data) => {
    setShowLoading(true);
    const payload = { email: data.email };
    await resetPassword(payload);
    setShowLoading(false);
    setOpenRegConf(true);
  };

  const handleConfirmation = () => {
    setOpenRegConf(false);
    navigate("/");
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.pageBackgroundSecondary,
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={2}>
        <img src={Logo} width="70%" alt={"FFCI"} />
      </Box>
      <Container maxWidth="sm" sx={{ backgroundColor: "white", padding: 4, borderRadius: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Reiniciar Contraseña
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography>
              Por favor introduce el correo electrónico con el cual te registraste en la plataforma.
              Te enviaremos un correo con los siguientes pasos para obtener acceso nuevamente.
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Correo Electrónico"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Enviar
            </Button>
          </form>
          <Link
            variant="body2"
            sx={{ marginTop: 2 }}
            onClick={() => navigate("/")}
            component="button"
          >
            Recuerdo mi contraseña, quiero iniciar sesión
          </Link>
        </Box>
      </Container>
      <Box
        position="fixed"
        bottom={isKeyboardOpen ? -100 : 16}
        left={16}
        display="flex"
        flexDirection="column"
      >
        <Link
          href="#"
          variant="body2"
          onClick={() => setOpenPopup1(true)}
          sx={{ marginBottom: 1 }}
          className="App-link"
        >
          Acuerdo de Privacidad
        </Link>
        <Link href="#" variant="body2" onClick={() => setOpenPopup2(true)} className="App-link">
          Acerca de Nosotros
        </Link>
      </Box>
      <DialogPrivacy openPopup={openPopup1} onCloseFn={() => setOpenPopup1(false)} />
      <DialogAboutUs openPopup={openPopup2} onCloseFn={() => setOpenPopup2(false)} />
      <DialogConfirmation
        open={openRegConf}
        onCloseFn={handleConfirmation}
        dialogText={"Revisa tu correo electrónico para obtener las siguientes instrucciones."}
      />
      <Loading show={showLoading} />
    </Box>
  );
};

export default NewPassword;
