import React from "react";
import { Typography, Container, Box } from "@mui/material";
import SideMenu from "../components/SideMenu";
import { useTheme } from "@mui/material/styles";
import AdminContent from "../components/AdminContent";

const OperatorDashboard = () => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.pageBackground, minHeight: "100vh" }}>
      <div style={{ display: "flex" }}>
        <SideMenu />
        <main style={{ flexGrow: 1, padding: "24px" }}>
          <div style={{ minHeight: "64px" }} />
          <Container>
            <Typography variant="h4" gutterBottom>
              Administración
            </Typography>
            <AdminContent />
          </Container>
        </main>
      </div>
    </Box>
  );
};

export default OperatorDashboard;
