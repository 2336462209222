import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SideMenu from "../components/SideMenu";
import { useTheme } from "@mui/material/styles";
import ConsultantForm from "../components/ConsultantForm";
import CreditsForm from "../components/CreditsForm";
import DocumentsForm from "../components/DocumentsForm";
import DebtReasons from "../components/DebtReasons";
import { useSelector } from "react-redux";
import { getConsultantByUserId } from "../services/consultantServices";
import { getCreditByConsultant } from "../services/creditServices";
import { getReasonsByConsultant } from "../services/reasonsServices";
import { getDocumentByConsultant } from "../services/docsServices";
import { REQUIRED_DOCS } from "../constants";
import Loading from "../components/Loading";

const UserDashboard = () => {
  const theme = useTheme();
  const userData = useSelector((state) => state.user.user);
  const [consultant, setConsultant] = useState({});
  const [credits, setCredits] = useState([]);
  const [reasons, setReasons] = useState(null);
  const [docs, setDocs] = useState([]);
  const [docsCompleted, setDocsCompleted] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      setShowLoading(true);
      if (userData.id) {
        const response = await getConsultantByUserId(userData.id);
        if (!ignore) {
          setConsultant(response);
        }
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [userData]);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      setShowLoading(true);
      if (consultant && consultant.id) {
        const credits = await getCreditByConsultant(consultant.id);
        const reasons = await getReasonsByConsultant(consultant.id);
        const docs = await getDocumentByConsultant(consultant.id);
        if (!ignore) {
          setCredits(credits);
          setReasons(reasons);
          setDocs(docs);
        }
      }
      setTimeout(() => {
        setShowLoading(false);
      }, 1200);
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, [consultant]);

  useEffect(() => {
    const hasAllRequiredDocs = REQUIRED_DOCS.every((requiredDoc) =>
      docs.some((doc) => doc.type === requiredDoc)
    );
    if (hasAllRequiredDocs) {
      setDocsCompleted(true);
    } else {
      setDocsCompleted(false);
    }
  }, [docs]);

  useEffect(() => {
    if (!consultant.completedAt) {
      setExpanded("panel1");
    } else if (!reasons || !reasons.createdAt) {
      setExpanded("panel2");
    } else if (credits.length === 0) {
      setExpanded("panel3");
    } else if (!docsCompleted) {
      setExpanded("panel4");
    } else {
      setExpanded("panel0");
    }
    // eslint-disable-next-line
  }, [consultant, reasons, docsCompleted]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.pageBackground, minHeight: "100vh" }}>
      <div style={{ display: "flex" }}>
        <SideMenu />
        <main style={{ flexGrow: 1, padding: "24px" }}>
          <div style={{ minHeight: "64px" }} />
          <Container>
            <Typography variant="h4" gutterBottom>
              {`¡Bienvenido, ${userData.name} ${userData.lastname}!`}
            </Typography>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">Instrucciones</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Gracias por tu interés en tomar una asesoría en FFCI. A continuación, puedes
                  completar la información solicitada, con el fin de poder bridarte un buen
                  servicio. Cuando hayas completado todos los pasos, la plataforma te lo indicará y
                  te dará el enlace para poder agendar tu primer cita de asesoría.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">1. Provee tu información personal y laboral</Typography>
                {consultant.completedAt && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      Completado
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Esta información nos será de utilidad para evaluar tu situación específica y poder
                  ofrecer ofrecer la mejor solución posible en tu asesoría con base a tu perfil
                  personal específico.
                </Typography>
                <ConsultantForm consultant={consultant} onModelChange={setConsultant} />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                disabled={consultant && !consultant.completedAt}
              >
                <Typography variant="h5">2. ¿Por qué crees que te endeudaste?</Typography>
                {reasons && reasons.createdAt && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      Completado
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  En función de comenzar con tu asesoría, nos gustaría saber cuáles crees que fueron
                  las razones por las cuales te endeudaste.
                </Typography>
                <DebtReasons
                  reasons={reasons}
                  consultantId={consultant.id}
                  onModelChange={setReasons}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} disabled={reasons === null}>
                <Typography variant="h5">3. Provee la información sobre tus créditos</Typography>
                {credits.length > 0 && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <InfoIcon color="primary" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      {`${credits.length} agregado(s)`}
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Utilizaremos esta información para conocer tu situación financiera actual y al
                  complementarse con la información sobre tu perfil personal, poder ayudarte para
                  saldar dichos créditos. Puedes agregar todos los créditos que tengas actualmente.
                </Typography>
                <CreditsForm
                  creditList={credits}
                  consultantId={consultant.id}
                  onModelChange={setCredits}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} disabled={credits.length === 0}>
                <Typography variant="h5">4. Sube tus documentos</Typography>
                {docsCompleted && (
                  <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                    <CheckCircleIcon color="success" />
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      Completado
                    </Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>
                  Por favor carga los documentos obligatorios para tu asesoría: Documento de
                  identificación, record crediticio, carta de autorización y estados de cuenta.
                  Puedes subir también otros documentos que consideres necesarios, como presupuesto
                  y análisis de créditos. Puedes subir archivos en formato JPG, PNG, PDF, DOC o XLS.
                </Typography>
                <DocumentsForm docs={docs} consultantId={consultant.id} onModelChange={setDocs} />
              </AccordionDetails>
            </Accordion>
            <Box>
              {credits.length > 0 && docsCompleted && (
                <Box sx={{ textAlign: "center", marginTop: "24px" }}>
                  <CheckCircleIcon color="success" sx={{ fontSize: 60 }} />
                  <Typography variant="h6" sx={{ marginTop: "8px" }}>
                    Ya has proporcionado toda la información necesaria para tu asesoría.
                  </Typography>
                  <Typography variant="h6">
                    <a href="https://wa.me/+50259749230" target="_blank" rel="noopener noreferrer">
                      <Box
                        component="span"
                        sx={{ display: "inline-flex", alignItems: "center", whiteSpace: "nowrap" }}
                      >
                        Ahora puedes agendar tu primer cita a través del
                        <WhatsAppIcon
                          sx={{ marginLeft: "4px", marginRight: "4px", fontSize: "1.25rem" }}
                        />{" "}
                        Whatsapp 5974 9230.
                      </Box>
                    </a>
                  </Typography>
                </Box>
              )}
            </Box>
          </Container>
          <Loading show={showLoading} />
        </main>
      </div>
    </Box>
  );
};

export default UserDashboard;
