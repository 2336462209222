import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Tooltip
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PropTypes from "prop-types";
import Loading from "./Loading";
import { FILE_TYPE } from "../constants";
import { createDoc, deleteDoc, downloadDoc } from "../services/docsServices";

const DocumentsForm = ({ docs, consultantId, onModelChange }) => {
  const [files, setFiles] = useState([]);
  const [fileType, setFileType] = useState(FILE_TYPE.DPI);
  const [showLoading, setShowLoading] = useState(false);
  const [loadText, setLoadText] = useState("Guardando");
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [fileName, setFileName] = useState("");
  const [upFile, setUpFile] = useState(null);
  const [note, setNote] = useState("");

  useEffect(() => {
    setFiles(docs);
  }, [docs]);

  const mapTypeNames = (name) => {
    if (name === FILE_TYPE.DPI) {
      return "Identificación";
    } else if (name === FILE_TYPE.RECORD) {
      return "Record Crediticio";
    } else if (name === FILE_TYPE.ESTADO) {
      return "Estado de Cuenta";
    } else if (name === FILE_TYPE.ACUERDO) {
      return "Reconocimiento";
    } else if (name === FILE_TYPE.PODER) {
      return "Carta Autorización";
    } else if (name === FILE_TYPE.PERSONAL) {
      return "Documento Asesoría";
    }
    return name;
  };
  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    setUpFile(file);
    setFileName(file.name.length > 22 ? file.name.slice(0, 20 - 1) + "..." : file.name);
    setUploadDisabled(false);
  };

  const handleUpload = async () => {
    setLoadText("Subiendo archivo");
    setShowLoading(true);
    const formData = new FormData();
    formData.append("document", upFile);
    formData.append("type", fileType);
    formData.append("consultantId", consultantId);
    formData.append("note", note);
    try {
      const response = await createDoc(formData);
      setUploadDisabled(true);
      setUpFile(null);
      setFileName("");
      onModelChange([...files, response]);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setShowLoading(false);
  };

  const handleDelete = async (fileId) => {
    setLoadText("Eliminando archivo");
    setShowLoading(true);
    try {
      await deleteDoc(fileId);
      onModelChange(files.filter((file) => file.id !== fileId));
    } catch (error) {
      console.error("Error deleting file:", error);
    }
    setShowLoading(false);
  };

  const handleDownload = async (fileId) => {
    setLoadText("Descargando archivo");
    setShowLoading(true);
    try {
      const response = await downloadDoc(fileId);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileId); // or use the actual file name from response
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setShowLoading(false);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };
  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  return (
    <Box sx={{ maxWidth: "85%", margin: "auto", padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Tipo de documento</InputLabel>
              <Select value={fileType} label="Tipo de Documento" onChange={handleFileTypeChange}>
                <MenuItem value={FILE_TYPE.DPI}>Documento de Identificación</MenuItem>
                <MenuItem value={FILE_TYPE.RECORD}>Record Crediticio</MenuItem>
                <MenuItem value={FILE_TYPE.PODER}>Carta Autorización</MenuItem>
                <MenuItem value={FILE_TYPE.ESTADO}>Estado de Cuenta</MenuItem>
                <MenuItem value={FILE_TYPE.ACUERDO}>Reconocimiento de Deuda</MenuItem>
                <MenuItem value={FILE_TYPE.PERSONAL}>Documento solicitado por la asesoria</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nota"
              type="text"
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 70 }}
              value={note}
              onChange={handleNoteChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" component="label" fullWidth startIcon={<AttachFileIcon />}>
              {fileName !== "" ? fileName : "Seleccionar archivo"}
              <input
                type="file"
                accept=".jpg,.png,.pdf,.doc,.xls,.docx,.xlsx"
                hidden
                onChange={handleFileSelection}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              startIcon={<FileUploadIcon />}
              disabled={uploadDisabled}
              onClick={handleUpload}
            >
              Subir archivo
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo de archivo</TableCell>
              <TableCell>Fecha de carga</TableCell>
              <TableCell>Nota</TableCell>
              <TableCell sx={{ width: "96px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => (
              <TableRow key={index}>
                <TableCell>{mapTypeNames(file.type)}</TableCell>
                <TableCell>{new Date(file.createdAt).toLocaleDateString("es-GT")}</TableCell>
                <TableCell>{file.note || ""}</TableCell>
                <TableCell>
                  <Tooltip title="Descarga tu documento">
                    <IconButton onClick={() => handleDownload(file.name)}>
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Elimina el documento">
                    <IconButton onClick={() => handleDelete(file.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Loading show={showLoading} text={loadText} />
    </Box>
  );
};

export default DocumentsForm;

DocumentsForm.propTypes = {
  docs: PropTypes.array,
  consultantId: PropTypes.number,
  onModelChange: PropTypes.func.isRequired
};
