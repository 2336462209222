import axios from "axios";
import { API_URL } from "../constants";

export const getCreditByConsultant = async (consultantId) => {
  try {
    const response = await axios.get(`${API_URL}/credit?consultantId=${consultantId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const createCredit = async (credit) => {
  try {
    const response = await axios.post(`${API_URL}/credit`, credit, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};

export const deleteConsultantCredit = async (creditId) => {
  try {
    const response = await axios.delete(`${API_URL}/credit/${creditId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response?.data || error;
  }
};
