import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const DialogWarning = ({ dialogText, open, onCloseFn, onConfirmFn }) => (
  <Dialog open={open} onClose={onCloseFn}>
    <DialogTitle>Confirmación</DialogTitle>
    <DialogContent>
      <DialogContentText>{dialogText}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCloseFn} color="primary">
        Cancelar
      </Button>
      <Button onClick={onConfirmFn} color="secondary" variant="contained">
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

export default DialogWarning;

DialogWarning.propTypes = {
  dialogText: PropTypes.string,
  open: PropTypes.bool,
  onCloseFn: PropTypes.func,
  onConfirmFn: PropTypes.func
};
