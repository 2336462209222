import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  TablePagination
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { getAllUsers, resetPassword, updateUserPermissions } from "../services/userServices";
import ManagePermissionsDialog from "./ManagePermissionsDialog";
import Loading from "./Loading";
import DialogWarning from "./DialogWarning";

const MainComponent = () => {
  const [permissionsDialogOpen, setPermissionsDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      if (!ignore) {
        const response = await getAllUsers();
        setAllUsers(response);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    setFilteredUsers(
      allUsers.filter((item) => {
        const searchFields = [item.name, item.lastname, item.email];
        return searchFields.some((field) =>
          field.toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
    );
  }, [searchQuery, allUsers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const handleManagePermissions = (user) => {
    setSelectedUser(user);
    setPermissionsDialogOpen(true);
  };

  const openResetPassword = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleSavePermissions = async (user) => {
    setShowLoading(true);
    const payload = { userId: user.id, roles: user.Roles };
    const response = await updateUserPermissions(payload);
    setShowLoading(false);
    if (response) {
      const response = await getAllUsers();
      setAllUsers(response);
      setSelectedUser({});
      setPermissionsDialogOpen(false);
    }
  };

  const handleResetPassword = async () => {
    setShowLoading(true);
    const payload = { email: selectedUser.email };
    await resetPassword(payload);
    setDialogOpen(false);
    setShowLoading(false);
  };

  return (
    <Box sx={{ backgroundColor: "white", borderRadius: "4px", boxShadow: 1, padding: "24px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Administración de usuarios
          </Typography>
          <TextField
            label="Introduce correo electrónico o nombres para buscar un usuario"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>Correo Electrónico</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.lastname}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Button color="primary" onClick={() => openResetPassword(user)}>
                          Reiniciar Contraseña
                        </Button>
                        <Button color="primary" onClick={() => handleManagePermissions(user)}>
                          Cambiar Permisos
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredUsers.length} // Replace with dynamic count
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Eliminar casos viejos
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Desde"
                  value={dateFrom}
                  onChange={setDateFrom}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Hasta"
                  value={dateTo}
                  onChange={setDateTo}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" sx={{ marginTop: "16px" }}>
            Buscar
          </Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>Fecha de finalización</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Replace with dynamic data */}
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>Jane</TableCell>
                  <TableCell>Smith</TableCell>
                  <TableCell>2023-10-01</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={5} // Replace with dynamic count
            rowsPerPage={rowsPerPage2}
            page={page2}
            onPageChange={handleChangePage2}
            onRowsPerPageChange={handleChangeRowsPerPage2}
          />
          <Button variant="contained" color="secondary" sx={{ marginTop: "16px" }}>
            Eliminar seleccionados
          </Button>
        </Grid>
      </Grid>
      <Loading show={showLoading} text={"Guardando"} />
      <DialogWarning
        open={dialogOpen}
        dialogText={`¿Estás seguro de que deseas reiniciar la contraseña para ${selectedUser?.email}?`}
        onCloseFn={() => setDialogOpen(false)}
        onConfirmFn={handleResetPassword}
      />
      <ManagePermissionsDialog
        open={permissionsDialogOpen}
        onClose={() => setPermissionsDialogOpen(false)}
        user={selectedUser}
        onSave={handleSavePermissions}
      />
    </Box>
  );
};

export default MainComponent;
