export const API_URL = "https://api.ffci-guatemala.org/api/v1";
Object.freeze(API_URL);

export const PASS_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
Object.freeze(PASS_PATTERN);

export const ROLE = {
  ADMIN: "admin",
  USER: "usuario",
  OPERATOR: "operador",
  CONSULTANT: "asesor"
};
Object.freeze(ROLE);

export const FILE_TYPE = {
  DPI: "DPI",
  RECORD: "RECORD",
  ESTADO: "ESTADO",
  ACUERDO: "ACUERDO",
  PODER: "PODER",
  PERSONAL: "PERSONAL"
};
Object.freeze(FILE_TYPE);

export const REQUIRED_DOCS = [FILE_TYPE.DPI, FILE_TYPE.RECORD, FILE_TYPE.ESTADO, FILE_TYPE.PODER];
Object.freeze(REQUIRED_DOCS);

export const debtReasons = [
  { label: "No sabe tomar decisiones inteligentes", id: "noDecisions", value: false },
  { label: "No sabe administrarse", id: "noAdministration", value: false },
  { label: "No sabe decir NO", id: "noNo", value: false },
  { label: "No tiene presupuesto", id: "noBudget", value: false },
  { label: "No tiene control de gastos", id: "noSpendControl", value: false },
  { label: "Es muy complaciente", id: "indulging", value: false },
  { label: "Gastos hormiga sin control", id: "antSpending", value: false },
  { label: "Tiene espíritu de salvador(a)", id: "saviour", value: false },
  { label: "Tiene desorden en su economía", id: "unordered", value: false },
  { label: "Un estilo de vida no razonable", id: "lifestyle", value: false },
  { label: "Mantiene ansiedad", id: "anxiety", value: false },
  { label: "Problemas de salud", id: "health", value: false },
  { label: "Presume del futuro", id: "future", value: false },
  { label: "No ha tomado un curso de educación financiera", id: "education", value: false }
];

Object.freeze(debtReasons);
