import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  InputAdornment
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import Loading from "./Loading";
import { createCredit, deleteConsultantCredit } from "../services/creditServices";

const CreditForm = ({ creditList, consultantId, onModelChange }) => {
  const { handleSubmit, control, reset } = useForm();
  const [credits, setCredits] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [loadText, setLoadText] = useState("Guardando");

  useEffect(() => {
    setCredits(creditList);
  }, [creditList]);

  const onSubmit = async (data) => {
    setLoadText("Guardando");
    setShowLoading(true);
    const payload = { ...data, consultantId };
    const response = await createCredit(payload);
    onModelChange([...credits, response]);
    reset();
    setShowLoading(false);
  };

  const deleteCredit = async (creditId) => {
    setLoadText("Eliminando");
    setShowLoading(true);
    await deleteConsultantCredit(creditId);
    onModelChange((prevCredits) => prevCredits.filter((credit) => credit.id !== creditId));
    setShowLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ maxWidth: "85%", margin: "auto", padding: 2 }}
    >
      <Typography variant="h6" gutterBottom>
        Agregar nuevo crédito a los registros
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="companyName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Nombre de Empresa"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 45 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="amount"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Monto de crédito"
                variant="outlined"
                fullWidth
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">Q</InputAdornment>
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="startDate"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Fecha de apertura"
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="interest"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="% de Interés"
                variant="outlined"
                fullWidth
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="note"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Notas"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<AddIcon />}
          >
            Agregar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ marginTop: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Empresa</TableCell>
                  <TableCell>Monto deuda</TableCell>
                  <TableCell>Fecha de apertura</TableCell>
                  <TableCell>% de Interés</TableCell>
                  <TableCell>Notas</TableCell>
                  <TableCell sx={{ width: "64px" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {credits.map((credit, index) => (
                  <TableRow key={index}>
                    <TableCell>{credit.companyName}</TableCell>
                    <TableCell>Q{credit.amount}</TableCell>
                    <TableCell>
                      {credit.startDate
                        ? new Date(credit.startDate).toLocaleDateString("es-GT")
                        : ""}
                    </TableCell>
                    <TableCell>{credit.interest} %</TableCell>
                    <TableCell>{credit.note ? credit.note : ""}</TableCell>
                    <TableCell>
                      <Tooltip title="Elimina el crédito">
                        <IconButton onClick={() => deleteCredit(credit.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Loading show={showLoading} text={loadText} />
    </Box>
  );
};

export default CreditForm;

CreditForm.propTypes = {
  creditList: PropTypes.array,
  consultantId: PropTypes.number,
  onModelChange: PropTypes.func.isRequired
};
