import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const DialogPrivacy = ({ openPopup, onCloseFn }) => {
  return (
    <Dialog open={openPopup} onClose={onCloseFn}>
      <DialogTitle>Acerca de Nosotros</DialogTitle>
      <DialogContent>
        <p>
          Somos un grupo de líderes, empresarios y emprendedores comprometidos en ser parte del
          movimiento de transformación global. Un movimiento basado en relaciones personales.
          Existimos para motivar y apoyar a empresarios, líderes y emprendedores a cumplir su
          propósito. Ofrecemos una serie de módulos para estudios individuales y en grupo.
        </p>
        <p>
          Nuestra visión es ser influenciadores de líderes profesionales y empresarios
          guatemaltecos, sembrando principios bíblicos que trasciendan a la siguiente generación a
          nivel mundial
        </p>
        <p>
          Nuestra misión es enseñar principios bíblicos a propietarios de negocios, líderes y
          ejecutivos guatemaltecos a través de grupos pequeños, acompañandoles en la aplicación y
          transformación de su vida personal y empresarial.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseFn} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogPrivacy.propTypes = {
  openPopup: PropTypes.bool,
  onCloseFn: PropTypes.func
};
export default DialogPrivacy;
