import { combineReducers } from "redux";
import userReducer from "./userSlice";
import consultantReducer from "./consultantSlice";

const rootReducer = combineReducers({
  user: userReducer,
  consultant: consultantReducer
});

export default rootReducer;
