import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const DialogPrivacy = ({ openPopup, onCloseFn }) => {
  return (
    <Dialog open={openPopup} onClose={onCloseFn}>
      <DialogTitle>Política de Privacidad</DialogTitle>
      <DialogContent>
        <p>
          La presente Política de Privacidad establece los términos en que FFCI Guatemala usa y
          protege la información que es proporcionada por sus usuarios al momento de utilizar su
          aplicación web. Esta organización está comprometida con la seguridad de los datos de sus
          usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted
          pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los
          términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el
          tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente
          esta página para asegurarse que está de acuerdo con dichos cambios.
        </p>
        <p>
          <strong>Información que es recogida</strong>
        </p>
        <p>
          Nuestra aplicación web podrá recoger información personal por ejemplo: Nombre,&nbsp;
          información de contacto como&nbsp; su dirección de correo electrónica e información
          demográfica. Así mismo cuando sea necesario podrá ser requerida información específica
          para dar el servicio adecuado de asesoría que usted ha solicitado.
        </p>
        <p>
          <strong>Uso de la información recogida</strong>
        </p>
        <p>
          Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio
          posible, particularmente para mantener un registro de usuarios y de su perfil.
        </p>
        <p>
          FFCI Guatemala está altamente comprometido para cumplir con el compromiso de mantener su
          información segura. Usamos los sistemas más avanzados y los actualizamos constantemente
          para asegurarnos que no exista ningún acceso no autorizado. Además de asegurarnos de
          eliminar su información un tiempo posterior a la culminación de su asesoría.
        </p>
        <p>
          <strong>Control de su información personal</strong>
        </p>
        <p>
          En cualquier momento usted puede decidir no proporcionar la información personal que es
          solicitada por nuestra aplicación web.&nbsp; En caso de dicha información ser
          absolutamente necesaria para su proceso, el asesor le indicará sobre esto. Si en algún
          caso usted desea eliminar su información de nuestra base de datos ya que no desea
          continuar con el proceso de asesoría, podrá eliminar su cuenta de la aplicación web a
          través del menú de su perfil.
        </p>
        <p>
          Esta organización no venderá, cederá ni distribuirá la información personal que es
          recopilada sin su consentimiento.
        </p>
        <p>
          FFCI Guatemala se reserva el derecho de cambiar los términos de la presente Política de
          Privacidad en cualquier momento.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseFn} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogPrivacy.propTypes = {
  openPopup: PropTypes.bool,
  onCloseFn: PropTypes.func
};
export default DialogPrivacy;
