import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@mui/material";
import { ROLE } from "../constants";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const ManagePermissionsDialog = ({ open, onClose, user, onSave }) => {
  const [selUser, setSelUser] = useState({});
  const [isUser, setIsUser] = useState(false);
  const [isOperator, setIsOperator] = useState(false);
  const [isConsultant, setIsConsultant] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setSelUser(user);
  }, [user]);

  useEffect(() => {
    if (selUser?.Roles) {
      setIsUser(selUser.Roles.some((role) => role.name === ROLE.USER));
      setIsOperator(selUser.Roles.some((role) => role.name === ROLE.OPERATOR));
      setIsConsultant(selUser.Roles.some((role) => role.name === ROLE.CONSULTANT));
      setIsAdmin(selUser.Roles.some((role) => role.name === ROLE.ADMIN));
    }
  }, [selUser]);

  const handleCheck = (param) => {
    switch (param) {
      case ROLE.USER:
        setIsUser(!isUser);
        setSelUser({
          ...selUser,
          Roles: isUser
            ? selUser.Roles.filter((role) => role.name !== ROLE.USER)
            : [...selUser.Roles, { name: ROLE.USER }]
        });
        break;
      case ROLE.OPERATOR:
        setIsOperator(!isOperator);
        setSelUser({
          ...selUser,
          Roles: isOperator
            ? selUser.Roles.filter((role) => role.name !== ROLE.OPERATOR)
            : [...selUser.Roles, { name: ROLE.OPERATOR }]
        });
        break;
      case ROLE.CONSULTANT:
        setIsConsultant(!isConsultant);
        setSelUser({
          ...selUser,
          Roles: isConsultant
            ? selUser.Roles.filter((role) => role.name !== ROLE.CONSULTANT)
            : [...selUser.Roles, { name: ROLE.CONSULTANT }]
        });
        break;
      case ROLE.ADMIN:
        setIsAdmin(!isAdmin);
        setSelUser({
          ...selUser,
          Roles: isAdmin
            ? selUser.Roles.filter((role) => role.name !== ROLE.ADMIN)
            : [...selUser.Roles, { name: ROLE.ADMIN }]
        });
        break;
      default:
        break;
    }
  };
  const handleOnClick = () => {
    onSave(selUser);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {"Configuración de Permisos"}{" "}
        <Typography>{`Usuario: ${selUser?.name} ${selUser?.lastname} (${selUser?.email})`}</Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem key={ROLE.USER}>
            <ListItemText primary="Usuario Asesorado" />
            <ListItemSecondaryAction>
              <Checkbox edge="end" checked={isUser} onChange={() => handleCheck(ROLE.USER)} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={ROLE.OPERATOR}>
            <ListItemText primary="Operador" />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                checked={isOperator}
                onChange={() => handleCheck(ROLE.OPERATOR)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={ROLE.CONSULTANT}>
            <ListItemText primary="Asesor" />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                checked={isConsultant}
                onChange={() => handleCheck(ROLE.CONSULTANT)}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem key={ROLE.ADMIN}>
            <ListItemText primary="Administrador" />
            <ListItemSecondaryAction>
              <Checkbox edge="end" checked={isAdmin} onChange={() => handleCheck(ROLE.ADMIN)} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleOnClick} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ManagePermissionsDialog;

ManagePermissionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    Roles: PropTypes.array
  }),
  onSave: PropTypes.func.isRequired
};
